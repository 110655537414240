export const CALL_LOCALE = {
  SEARCH_IN_CHANNEL: '대화 내 검색',
  SEARCH: '검색',
  NO_SEARCHED_MESSAGE: '검색된 내용이 없습니다.',
  SEARCH_IN: '검색: ',
  SEARCHING: '메시지 검색 중…',
  RenderMessageParamsTypeMESSAGE_INPUT__PLACE_HOLDER: '메시지를 입력하세요.',
  PLACE_HOLDER__NO_MESSAGES: '메시지가 없습니다.',
}

export const CALL_STATUS = {
  DECLINED: 'declined',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
}

export const CALL_MESSAGES = {
  CALL_FAILED: '상담사와 연결하지 못했어요. 잠시 후 다시 시도해 주세요.',
  CALL_CANCELED: '취소',
  CALL_ENDED: '전화 상담이 종료되었어요.',
}
